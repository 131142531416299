import React, { useEffect } from "react"
import loadable from '@loadable/component'

import SEO from "../components/SEO"
import Layout from "../components/Layout"
import keywords from "../constants/keywords"
import BtnArrow from "../assets/images/button_arrow-top.svg"
import BtnArrowbottom from "../assets/images/button_arrowbottom.svg"

const faqPage = () => {

    return (
        <>
            <Layout black={false}>
                <SEO
                    title={"FAQ | Differnt Systems"}
                    description={"Find answers to your questions about our custom software, automation, and cloud services. Obtain the information you need to make informed decisions."}
                    customKeywords={keywords.faq.keywords}
                />

                <div class="offcanvas-wrap">

                    {/* accordion */}
                    <section className="pb-15 py-lg-15 py-xl-15">
                        <div style={{ paddingTop: '4%' }} className="container mt-2">
                            <div className="row mb-10">
                                <div className="col text-left">
                                    <span className="hero-label badge bg-muted-purple text-white rounded-pill mb-3">Frequently Asked Questions</span>
                                    <h1 className="mb-1">Why Wonder?</h1>
                                    <p className="">You have questions, and we're happy to answer them all.</p>
                                    <a href="/contact/" className='button-link'>More Questions? Contact Us
                                        <div className="arrow_movement">
                                            <img src={BtnArrow} alt='' />
                                            <img src={BtnArrowbottom} alt='' />
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div className="row g-3 g-xl-5">
                                <div className="col-lg-6">
                                    <div className="card bg-black-200">
                                        <div className="card-body">
                                            <h3 className="fs-4 my-3">Working Together</h3>
                                            <div className="accordion" id="accordion-1">
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header" id="heading-1-1">
                                                        <button className="accordion-button lead collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-1-1" aria-expanded="false" aria-controls="collapse-1-1">
                                                            Will my project and our conversation(s) remain confidential?
                                                        </button>
                                                    </h2>
                                                    <div id="collapse-1-1" className="accordion-collapse collapse" aria-labelledby="heading-1-1" data-bs-parent="#accordion-1">
                                                        <div className="accordion-body">
                                                            <p className="faq-section-text">Yes. We prioritize confidentiality and gladly offer our standard NDA (non-disclosure agreement) to safeguard your intellectual property.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header" id="heading-1-2">
                                                        <button className="accordion-button lead collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-1-2" aria-expanded="false" aria-controls="collapse-1-2">
                                                            Do you take equity? Work for equity?
                                                        </button>
                                                    </h2>
                                                    <div id="collapse-1-2" className="accordion-collapse collapse" aria-labelledby="heading-1-2" data-bs-parent="#accordion-1">
                                                        <div className="accordion-body">
                                                            <p className="faq-section-text">No. Long-term collaboration is our goal, serving as your technology partner rather than your investor.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header" id="heading-1-3">
                                                        <button className="accordion-button lead collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-1-3" aria-expanded="false" aria-controls="collapse-1-3">
                                                            Could you assist with fundraising or connect us to investors?
                                                        </button>
                                                    </h2>
                                                    <div id="collapse-1-3" className="accordion-collapse collapse" aria-labelledby="heading-1-3" data-bs-parent="#accordion-1">
                                                        <div className="accordion-body">
                                                            <p className="faq-section-text">Unfortunately, not at this time.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header" id="heading-1-4">
                                                        <button className="accordion-button lead collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-1-4" aria-expanded="false" aria-controls="collapse-1-4">
                                                            Are you able to help startups?
                                                        </button>
                                                    </h2>
                                                    <div id="collapse-1-4" className="accordion-collapse collapse" aria-labelledby="heading-1-4" data-bs-parent="#accordion-1">
                                                        <div className="accordion-body">
                                                            <p className="faq-section-text">Yes. We're eager to support startups, offering guidance from inception, aiding with architecture and ideation. Prior to engagement, a basic business plan encompassing research, monetization strategy, cost structure, and objectives is advised.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card border mt-3 mt-xl-5">
                                        <div className="card-body">
                                            <h3 className="fs-4 my-3">Project Logistics</h3>
                                            <div className="accordion" id="accordion-2">
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header" id="heading-2-1">
                                                        <button className="accordion-button lead collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-2-1" aria-expanded="false" aria-controls="collapse-2-1">
                                                            How will we communicate?
                                                        </button>
                                                    </h2>
                                                    <div id="collapse-2-1" className="accordion-collapse collapse" aria-labelledby="heading-2-1" data-bs-parent="#accordion-2">
                                                        <div className="accordion-body">
                                                            <p className="faq-section-text">You'll be assigned a dedicated account representative that is available to you via call, text, or email. For any virtual meetings, we'll share a Google Meet with you.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div className="accordion-item">
                                                    <h2 className="accordion-header" id="heading-2-2">
                                                        <button className="accordion-button lead collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-2-2" aria-expanded="false" aria-controls="collapse-2-2">
                                                            How long will my project take?
                                                        </button>
                                                    </h2>
                                                    <div id="collapse-2-2" className="accordion-collapse collapse" aria-labelledby="heading-2-2" data-bs-parent="#accordion-2">
                                                        <div className="accordion-body">
                                                            <p className="faq-section-text">It really depends on the size and scale of the project. Some projects take as little as 3 weeks, others over 8 months. However, we understand that sometimes you need a faster than normal turnaround. Let our team know, and we'll work with you to find a way to hit your deadline!</p>
                                                        </div>
                                                    </div>
                                                </div> */}
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header" id="heading-2-3">
                                                        <button className="accordion-button lead collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-2-3" aria-expanded="false" aria-controls="collapse-2-3">
                                                            What is my level of involvement?
                                                        </button>
                                                    </h2>
                                                    <div id="collapse-2-3" className="accordion-collapse collapse" aria-labelledby="heading-2-3" data-bs-parent="#accordion-2">
                                                        <div className="accordion-body">
                                                            <p className="faq-section-text">The scope of the project will determine the flexibility of your involvement. Your participation is essential throughout the initial ideation, engineering and design stages. However, if you would prefer, we can switch to routine check-ins once we have the proper instruction from you.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div className="accordion-item">
                                                    <h2 className="accordion-header" id="heading-2-4">
                                                        <button className="accordion-button lead collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-2-4" aria-expanded="false" aria-controls="collapse-2-4">
                                                            What happens after project launch?
                                                        </button>
                                                    </h2>
                                                    <div id="collapse-2-4" className="accordion-collapse collapse" aria-labelledby="heading-2-4" data-bs-parent="#accordion-2">
                                                        <div className="accordion-body">
                                                            <p className="faq-section-text">After completion of your project, enjoy all the advantages of having in-house engineers, designers, developers, and cloud infrastructure specialists to continue and upkeep your project without the high cost and training. From hosting, updates and security patches,
                                                                to training sessions and help desk support— we provide the resources for your success.
                                                                <br />
                                                                <br />
                                                                <i>Flat-Rate Monthly Fee with No Long-Term Contracts</i></p>
                                                        </div>
                                                    </div>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="card border">
                                        <div className="card-body">
                                            <h3 className="fs-4 my-3">About Differnt Systems</h3>
                                            <div className="accordion" id="accordion-3">
                                                {/* <div className="accordion-item">
                                                    <h2 className="accordion-header" id="heading-3-1">
                                                        <button className="accordion-button lead collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-3-1" aria-expanded="false" aria-controls="collapse-3-1">
                                                            Are project proposals really free? How long does it take?
                                                        </button>
                                                    </h2>
                                                    <div id="collapse-3-1" className="accordion-collapse collapse" aria-labelledby="heading-3-1" data-bs-parent="#accordion-3">
                                                        <div className="accordion-body">
                                                            <p className="faq-section-text">Completely free! We're happy to chat about your project, give you some ideas, and dive into the details with you. We'll provide a full document with deliverables, costs and time estimations.
                                                            <br/>
                                                            <br/>
                                                            The turnaround time for quotes depend on the scope of the project. Most projects require a Technical Meeting to ensure nothing is missed, so quotes can take 1-2 weeks. However, for many smaller, simpler projects such as a 5-page website or landing page... we can get you a quote in 24hrs!
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div> */}
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header" id="heading-3-2">
                                                        <button className="accordion-button lead collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-3-2" aria-expanded="false" aria-controls="collapse-3-2">
                                                            Did F&S Digital close? Get bought out?
                                                        </button>
                                                    </h2>
                                                    <div id="collapse-3-2" className="accordion-collapse collapse" aria-labelledby="heading-3-2" data-bs-parent="#accordion-3">
                                                        <div className="accordion-body">
                                                            <p className="faq-section-text">Not at all! We're the same company, run by the same people, just with a new name that better reflects who we are and what we do.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header" id="heading-3-3">
                                                        <button className="accordion-button lead collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-3-3" aria-expanded="false" aria-controls="collapse-3-3">
                                                        Are warranties provided?
                                                        </button>
                                                    </h2>
                                                    <div id="collapse-3-3" className="accordion-collapse collapse" aria-labelledby="heading-3-3" data-bs-parent="#accordion-3">
                                                        <div className="accordion-body">
                                                            <p className="faq-section-text">Yes. For software-related projects, we offer an industry-leading six-month warranty. For hardware-related projects, we offer customized warranties and support.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card border mt-3 mt-xl-5">
                                        <div className="card-body">
                                            <h3 className="fs-4 my-3">Pricing &amp; Payments</h3>
                                            <div className="accordion" id="accordion-4">
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header" id="heading-4-1">
                                                        <button className="accordion-button lead collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-4-1" aria-expanded="false" aria-controls="collapse-4-1">
                                                            What's the usual cost for a project?
                                                        </button>
                                                    </h2>
                                                    <div id="collapse-4-1" className="accordion-collapse collapse" aria-labelledby="heading-4-1" data-bs-parent="#accordion-4">
                                                        <div className="accordion-body">
                                                            <p className="faq-section-text">
                                                            Given the uniqueness of each project, pricing varies significantly. Feel free to request a no-obligation quote, valid for 30 days.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header" id="heading-4-3">
                                                        <button className="accordion-button lead collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-4-3" aria-expanded="false" aria-controls="collapse-4-3">
                                                            What payment methods do you accept?
                                                        </button>
                                                    </h2>
                                                    <div id="collapse-4-3" className="accordion-collapse collapse" aria-labelledby="heading-4-3" data-bs-parent="#accordion-4">
                                                        <div className="accordion-body">
                                                            <p className="faq-section-text">We accept all major debit/credit cards, wire/ACH transfers, and checks.<br></br><br></br><i>Currently, there are no additional fees for credit/debit card payments.</i></p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header" id="heading-4-4">
                                                        <button className="accordion-button lead collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-4-4" aria-expanded="false" aria-controls="collapse-4-4">
                                                            What is the payment schedule? Do you offer financing?
                                                        </button>
                                                    </h2>
                                                    <div id="collapse-4-4" className="accordion-collapse collapse" aria-labelledby="heading-4-4" data-bs-parent="#accordion-4">
                                                        <div className="accordion-body">
                                                            <p className="faq-section-text">Software-related Projects:  50% payment at project initiation and the remaining 50% upon completion.
                                                                <br /><br />
                                                                Hardware-related Projects: Full payment due 30 days upon invoicing.
                                                                <br /><br />
                                                                <i>We offer a range of payment plans and financing partners tailored to the size and scope of each project.</i></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </Layout>
        </>
    )
}

export default faqPage
